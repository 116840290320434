<template>
	<w-layout fill-height ma-2 justify-center align-center row wrap>
		<w-flex text-xs-center xs4>
			<w-text-info :text="$t('holding.no_modules')"></w-text-info>
		</w-flex>
	</w-layout>
</template>

<script>

export default {
	name: 'NoHoldingModuleAvailable',
}
</script>
